var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-3"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.tableHeaders,"items":_vm.table.dataTable.data,"loading":_vm.table.loading,"show-select":_vm.withAction,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"ma-2",attrs:{"size":"50"}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getPhoto(item),"contain":""},on:{"click":() => {
                if (_vm.getPhoto(item)) {
                  _vm.showAttachment = true;
                  _vm.attachmentFile = _vm.getPhoto(item);
                }
              }}})],1),_c('div',[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.nis)+" ")])])],1)]}},{key:"item.file",fn:function({ item }){return [(item.file != '' && item.file != null)?_c('v-avatar',{attrs:{"size":"50"},on:{"click":() => {
            if (item.file) {
              _vm.showAttachment = true;
              _vm.attachmentFile = item.file;
            }
          }}},[(_vm.validateImage(item.file))?_c('v-img',{style:(item.file && 'cursor: pointer'),attrs:{"src":item.file}}):_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-attachment")])],1):_vm._e()]}},{key:"item.date_in",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date_in))+" ")]}},{key:"item.date_out",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date_out))+" ")]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.dateHoursFormat(item.created_at))+" ")]}},{key:"item.description",fn:function({ item }){return [(item.description)?_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()]}},{key:"item.action",fn:function({ item }){return [_c('v-btn',{staticClass:"primary mr-2",attrs:{"icon":"","small":"","dark":""},on:{"click":function($event){return _vm.detailItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"error",attrs:{"disabled":_vm.modelTable.length !== 0,"icon":"","small":"","dark":""},on:{"click":function($event){return _vm.dialogDelete(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}]),model:{value:(_vm.dataMultiple),callback:function ($$v) {_vm.dataMultiple=$$v},expression:"dataMultiple"}}),_c('Dialog',{attrs:{"open":_vm.openDetail,"data":_vm.itemPermission},on:{"close":function($event){_vm.openDetail = false}}}),_c('view-image',{attrs:{"view-file":_vm.showAttachment,"url-file":_vm.attachmentFile},on:{"close":function($event){_vm.showAttachment = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }