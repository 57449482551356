<template>
  <div class="pa-3">
    <v-data-table
      v-model="dataMultiple"
      :headers="tableHeaders"
      :items="table.dataTable.data"
      :loading="table.loading"
      :show-select="withAction"
      disable-pagination
      hide-default-footer
      class="elevation-0"
    >
      <template #item.name="{ item }">
        <div class="d-flex align-center">
          <v-avatar class="ma-2" size="50">
            <v-img
              :src="getPhoto(item)"
              style="cursor: pointer"
              contain
              @click="
                () => {
                  if (getPhoto(item)) {
                    showAttachment = true;
                    attachmentFile = getPhoto(item);
                  }
                }
              "
            />
          </v-avatar>
          <div>
            <div>
              {{ item.name }}
            </div>
            <div class="caption">
              {{ item.nis }}
            </div>
          </div>
        </div>
      </template>
      <template #item.file="{ item }">
        <v-avatar
          v-if="item.file != '' && item.file != null"
          size="50"
          @click="
            () => {
              if (item.file) {
                showAttachment = true;
                attachmentFile = item.file;
              }
            }
          "
        >
          <v-img
            v-if="validateImage(item.file)"
            :src="item.file"
            :style="item.file && 'cursor: pointer'"
          />
          <v-icon style="cursor: pointer" v-else>mdi-attachment</v-icon>
        </v-avatar>
      </template>
      <template #item.date_in="{ item }">
        {{ dateFormat(item.date_in) }}
      </template>
      <template #item.date_out="{ item }">
        {{ dateFormat(item.date_out) }}
      </template>
      <template #item.created_at="{ item }">
        {{ dateHoursFormat(item.created_at) }}
      </template>
      <template #item.description="{ item }">
        <span
          v-if="item.description"
          class="d-inline-block text-truncate"
          style="max-width: 150px;"
        >
          {{ item.description }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon small dark class="primary mr-2" @click="detailItem(item)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          :disabled="modelTable.length !== 0"
          icon
          small
          dark
          class="error"
          @click="dialogDelete(item)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <Dialog
      :open="openDetail"
      :data="itemPermission"
      @close="openDetail = false"
    />
    <view-image
      :view-file="showAttachment"
      :url-file="attachmentFile"
      @close="showAttachment = false"
    />
  </div>
</template>

<script>
import moment from "moment/moment";
moment.locale("id");
import { isExternal, isImageFile } from "@/utils/validate";

export default {
  props: {
    table: Object,
    withAction: Boolean,
    modelTable: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dataMultiple: {
      set(value) {
        this.$emit("setMultipleDelete", value);
      },
      get() {
        return this.modelTable;
      }
    },
    tableHeaders() {
      let header = [
        {
          text: this.$i18n.t("permission.permission"),
          align: "left",
          value: "status_izin"
        },
        {
          text: this.$i18n.t("permission.date_filing"),
          value: "created_at"
        },
        {
          text: this.$i18n.t("permission.start_date"),
          value: "date_in"
        },
        { text: this.$i18n.t("permission.end_date"), value: "date_out" },
        {
          text: this.$i18n.t("permission.description"),
          value: "description",
          width: 120
        },
        {
          text: this.$i18n.t("permission.attachment"),
          value: "file",
          width: 100
        }
      ];
      const action = {
        text: this.$i18n.t("app.action"),
        value: "action",
        align: "center",
        width: 100
      };
      const name = { text: this.$i18n.t("app.name"), value: "name" };
      const isStudent = this.$store.getters.g_role_type === "STUDENT";
      if (this.withAction) header.push(action);
      if (!isStudent) header.splice(0, 0, name);
      return header;
    }
  },
  components: {
    Dialog: () => import("./Dialog"),
    ViewImage: () => import("@/views/admin/academic/eTest/components/ViewImage")
  },
  data() {
    return {
      itemPermission: {},
      openDetail: false,
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      showAttachment: false,
      attachmentFile: ""
    };
  },
  methods: {
    validateImage: name => isImageFile(name),
    getPhoto(item) {
      let photo = "";
      if (isExternal(item.photo)) {
        photo = item.photo;
      } else {
        switch (item.gender) {
          case "L":
            photo = this.boyPhoto;
            break;
          case "P":
            photo = this.girlPhoto;
            break;
          default:
            photo = this.boyPhoto;
            break;
        }
      }
      return photo;
    },
    dialogDelete(item) {
      if (item.person) {
        this.$emit("setSingleDelete", item);
      }
    },
    detailItem(item) {
      this.itemPermission = item;
      this.openDetail = true;
    },
    dateFormat: date => moment(date).format("DD MMM YYYY"),
    dateHoursFormat: date => moment(date).format("DD MMM YYYY, HH:mm")
  }
};
</script>
